<template>
  <el-card shadow="never">
    <el-form inline>
      <el-form-item>
        <el-input v-model="formData.lendOrderCode" placeholder="借出单号" clearable style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="formData.id" placeholder="借出单明细ID" clearable style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="formData.materialCode" placeholder="备件编码" clearable style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formData.subClass" placeholder="备件小类" clearable style="width: 200px" filterable>
          <el-option v-for="item in subClassList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formData.noRmaReason" placeholder="不可索赔原因" clearable style="width: 200px" filterable>
          <el-option v-for="item in noRmaReasonList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formData.rmaRejudgeReasonName" placeholder="索赔复判理由说明" clearable style="width: 200px" filterable>
          <el-option v-for="item in rmaRejudgeReasonNameList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save" :loading="loading">保存</el-button>
      </el-form-item>
      <div style="color: orange;font-size: 12px">提示：1、保存时自动忽略已经维护过数据的备件。2、不填备件编码时表示修改该借出单下所有备件。3、备件来源为"索赔退回"时"不可索赔原因"有效，为"调拨订单"时"索赔复判理由说明"有效。</div>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: "LendOrderItemManage",
  data() {
    return {
      formData: {
        lendOrderCode: '',
        materialCode: '',
        subClass: '',
        noRmaReason: '',
        rmaRejudgeReasonName: '',
      },
      loading: false
    }
  },
  computed: {
    subClassList() {
      return this.$store.getters.getDictList('subClass') || [];
    },
    noRmaReasonList() {
      return this.$store.getters.getDictList('noRmaReason') || [];
    },
    rmaRejudgeReasonNameList() {
      return this.$store.getters.getDictList('rmaRejudgeReasonName') || [];
    }
  },
  methods: {
    save() {
      if (!this.formData.lendOrderCode) {
        return this.$message.error('借出单号不能为空！');
      }
      if (!this.formData.subClass && !this.formData.noRmaReason && !this.formData.rmaRejudgeReasonName) {
        return this.$message.error('备件小类、不可索赔原因和索赔复判理由说明不能全为空！');
      }

      this.loading = true;
      this.$axios.post('lendOrder/updateItem', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        const count = res.data || 0;

        this.formData.lendOrderCode = ''
        this.formData.id = ''
        this.formData.materialCode = ''
        this.formData.subClass = ''
        this.formData.noRmaReason = ''
        this.formData.rmaRejudgeReasonName = ''
        return this.$message.success('操作成功，更新条数：' + count);
      }, error => {
        this.loading = false;
        return this.$message.error('操作失败，' + error.message);
      });

    }
  }
}
</script>

<style scoped>

</style>